.chap_accordion .accordion-button::after {
    /* transform: none !important;
    transition: none !important; */
    content: none !important; /* Remove the chevron icons */
  }
  /* .chap_accordion .accordion-button{
    transform: none !important;
    transition: none !important;
  } */
  .custom_course_accordian .accordion-button {
    padding: 0 !important;
  }
  .custom_pdf_viewer .toolbar{
    display: none !important;
  }