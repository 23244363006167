/* .section-heading {
    font-weight: bold;
    margin-left: 20px;
  }
  
  .withdraw-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header-section {
    display: flex;
    justify-content: space-between;
  }
  
  .request-title {
    color: #ff9900;
    font-weight: bold;
  }
  
  .request-time, .request-status {
    color: #6c757d;
    font-size: 0.9rem;
  }
  
  .icon-placeholder {
    font-size: 1.5rem;
    color: #ff9900;
  }
  
  .details-section p {
    margin: 0;
  }
  
  .total-amount {
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
  
  .label {
    color: #6c757d;
    font-size: 0.85rem;
    margin: 0;
  }
  
  .amount {
    font-weight: bold;
    color: #212529;
  }
   */

   /* Wrapper to align the heading and card container */
.content-wrapper {
    max-width: 600px; /* Set a consistent width for both heading and card */
    width: 100%;
  }
  
  /* Heading styling */
  .section-heading {
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* Card container styling */
  .card-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* padding: 20px; */
  }
  
  /* Header section styling */
  .header-section {
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
  }
  
  .request-title {
    font-weight: bold;
  }
  
  .request-time {
    color: #6c757d;
    font-size: 0.9rem;
  }
  
  .icon-placeholder {
    font-size: 1.5rem;
    color: #ff9500;
  }
  
  /* Details section */
  .details-section p {
    margin: 0;
  }
  
  .text-gray {
    color: #6c757d;
  }
  
  .label {
    color: #6c757d;
    font-size: 0.85rem;
    margin: 0;
  }
  
  .amount {
    font-weight: bold;
    color: #212529;
  }
  