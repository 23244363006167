.ChatModule {
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.contai {
  border: 1px solid white;
  margin-top: 9px;
  border-top: 2px solid rgb(229, 227, 227);
}
.Sidebar {
  flex: 1;
}

.ChatMessage {
  flex: 2;
}

.HeaderSidebar {
  .text {
    font-size: 35px;
  }
}

.scroll::-webkit-scrollbar {
  width: 0px;
}

.scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}
.scroll::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.pb-13rem {
  padding-bottom: 13rem !important;
}
.scroll {
  /* height: 62vh; */
  overflow-y: scroll;
  .unread-count {
    background-color: #00c5de;
    width: 27px;
    border-radius: 12px;
  }
  .counter-text {
    color: white;
    margin-left: 5px;
  }
  .unread {
    background-color: rgba(0, 196, 222, 0.15);
  }

  .usersidebarchat {
    display: flex;
    gap: 10px;
    cursor: pointer;
    border: 1px solid #bcbcbc;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
    padding-left: 20px;
    transition: background-color 0.3s;
  }

  .usersidebarchat.clicked {
    position: relative;
  }

  .usersidebarchat.clicked::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 100%;
    border-left: 5px solid #00c5de;
    border-radius: 8px;
  }

  .img {
    width: 60px;
    height: 60px;
    border-radius: 24px;
    object-fit: cover;
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.16);
  }

  .username {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
  }

  .sidebarimg {
    margin-left: -12px;
    margin-top: 1px;
    width: 67px;
    height: 67px;
  }
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.Message::-webkit-scrollbar {
  width: 0px;
}

.Message::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.Message::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.Message::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.Headerchat {
  border-left: 2px solid rgb(229, 227, 227);

  .Message {
    border-top: 2px solid rgb(229, 227, 227);
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 8px;
    padding-bottom: 12rem;
  }

  .sender-name {
    font-size: 10px;
  }

  .message-with-sender {
    display: flex;
  }

  .date-header {
    display: flex;
    justify-content: center;

    padding: 8px 16px;
    margin: 10px 0;

    font-weight: bold;
    font-size: 16px;
    color: #333;
  }

  .centered-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
  }

  .click-text {
    font-size: 18px;
    font-weight: bold;
    color: #555;
  }

  .sender {
    border-radius: 10px 10px 0 0px;
  }

  .receiver {
    border-radius: 10px 10px 0px 0;
  }

  .spinner {
    display: flex;
    justify-content: center;
  }

  .msgitem {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;

    &.receiver {
      justify-content: flex-start;
      height: auto;
    }

    .img-container {
      max-width: 300px;
      height: auto;
    }

    .attachment-img {
      width: 100%;
      height: auto;
    }

    .msgtext {
      background-color: #ebebee;
      padding: 7px 20px;
      border-radius: 8px 8px 8px 0px;
      height: auto;
      width: auto;
      max-width: 800px;
      margin-bottom: 2px;
      word-wrap: break-word;
    }

    .msgtexts {
      background-color: #ebebee;
      padding: 10px;
      border-radius: 0px 0px 20px 0px;
      padding-left: 30px;
      height: auto;
      width: auto;
      max-width: 346px;
      padding-right: 30px;
      margin-bottom: 2px;
      word-wrap: break-word;
    }

    .msgtextsender {
      background-color: white;
      padding: 10px;
      border: 1px solid black;
      border-radius: 0px 0px 0px 20px;
      padding-left: 30px;
      height: auto;
      width: auto;
      max-width: 346px;
      padding-right: 30px;
      margin-bottom: 2px;
      word-wrap: break-word;
    }

    &.sender .msgtext {
      background-color: white;
      border-radius: 8px 8px 0px 8px;
      border: 1px solid black;
      font-size: 16px;
      line-height: 20px;
    }

    .msgtime {
      font-size: 10px;
    }
  }

  .Input {
    /* height: 52px; */
    width: 63%;
    background-color: white;
    padding: 10px;
    border-top: 2px solid rgb(229, 227, 227);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .send {
      border: none !important;
      background-color: #00c5de;
      padding: 4px;
      border-radius: 4px;
    }

    .writemsg {
      box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
      padding: 5px;
      background-color: #ffffff;
      border: 3px solid whitesmoke;
      outline: none;
      height: auto;
      max-height: 40px;
      transition: max-height 0.3s ease;
      border-radius: 20px !important;
    }

    .expanded {
      max-height: none;
    }
    .writemsg::-webkit-scrollbar {
      width: 5px;
    }

    .writemsg::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    .writemsg::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
    }
    .writemsg::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }

    .spiner {
      width: 32px;
      height: 30px;
    }
  }
  .Chatinfo {
    height: 62px;
  }
  .selected-img {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.16);
    padding: 2px;
  }
  .chatheadertext {
    font-size: 18px;
    font-weight: bold;
  }
}

.Messages {
  background-color: #f9f9f9;
  padding: 10px;
  height: 100vh;
  border-bottom: 2px solid rgb(230, 226, 226);
  overflow-y: scroll;
}

.ChatMessage {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
}

.ChatMessage.visible {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1320px) {
  .msgtext {
    max-width: 38rem !important;
  }

  .HeaderSidebar .container-fluid {
    padding-right: 0;
  }
  .usersidebarchat {
    padding-left: 6px !important;
  }
  .scroll .img {
    width: 45px;
    height: 45px;
  }
  .scroll .sidebarimg {
    width: 50px;
    height: 50px;
  }
  .userchatinfo {
    padding-left: 13px;
  }
}
.plus {
  background-color: #ffffff;
  padding: 4px;
  border-radius: 3px;
  display: flex;
}
@media (min-width: 1344px) {
  .scroll {
    height: 72vh;
  }
}

@media (min-width: 2500px) {
  .scroll {
    height: 74vh !important;
  }
}

@media (max-width: 768px) {
  .Sidebar {
    border-right: none;
  }

  .scroll {
    width: 100%;
  }
}
.chat-res .col1 {
  width: 35%;
  transition: all 0.3s ease-in;
}
.chat-res .col2 {
  width: 65%;
}
.chat-res .col1-w-0 {
  width: 0%;
  display: none;
  transition: all 0.3s ease-in;
}

@media (max-width: 990px) {
  .Input {
    width: 99.5% !important;
  }
}
@media (max-width: 991px) {
  .Input {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .Headerchat {
    border-left: none;
    margin-left: 0;
  }
  .HeaderSidebar {
    .text {
      font-size: 20px;
    }
  }
  .msgtext {
    max-width: 29rem !important;
  }
  .chat-res .col1 {
    width: 400px;
    position: absolute;
    background-color: white;
    transition: all 0.3s ease-in;
  }
  .chat-res .col2 {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .Headerchat .chatheadertext {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .Input {
    width: 98% !important;
  }
  .username {
    font-size: 14px !important;
  }
  .Headerchat .msgitem .msgtext {
    font-size: 14px;
  }
  .chatheadertext {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 153px;
  }
  .chat-res .col1 {
    width: 320px;
  }
  .flex-column-480 {
    flex-direction: column;
  }
  .date-time {
    display: flex;
    justify-content: space-around;
    margin-top: -10px !important;
  }
  .date-time p {
    margin: 0;
    margin-right: -10rem;
    font-size: 13px;
  }
  .message-text {
    margin: 0;
    font-size: 13px;
  }
}
