@import url("./fonts.css"); /* Fonts CSS */
body {
  font-family: 'NotoSans', sans-serif;
}
body {
  background-color: #f0f7fa !important;
  overflow-x: hidden !important;
}

.view-more-btn {
  color: #8a93a1;
}
.follow-btn {
  background-color: rgba(29, 193, 221, 0.8);
}
.follow-btn:hover {
  background-color: rgba(29, 193, 221, 1);
}

.filter-active {
  font-weight: bold;
}

/* calendar */
.fc .fc-col-header-cell.fc-day .fc-scrollgrid-sync-inner {
  background-color: rgba(29, 193, 221, 0.1) !important;
}
.fc .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
  color: #000 !important;
}
.fc .fc-button-group {
  gap: 10px !important;
}
.fc .fc-button-primary {
  background-color: #f7f7f7 !important;
  color: #000 !important;
  border-color: rgba(132, 135, 152, 0.3) !important;
  box-shadow: 0 2.46px 9.83px 0 rgba(217, 217, 217, 0.32) !important;
  display: flex !important;
  align-self: center !important;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.fc-next-button.fc-button.fc-button-primary {
  border-top-right-radius: 4.91px !important;
  border-bottom-right-radius: 4.91px !important;
}
.fc-prev-button.fc-button.fc-button-primary {
  border-top-left-radius: 4.91px !important;
  border-bottom-left-radius: 4.91px !important;
}
.fc-toolbar-title {
  font-size: 19px !important;
}
.fc-daygrid-day-number {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #000 !important;
}
.fc-button-group .fc-button.fc-button-primary {
  font-size: 15px !important;
  padding: 3px 0.35em !important;
}
/*  */
/* Shimmer */
.shimmer-center {
  justify-items: center !important;
}
.shimmer-thumbnail {
  min-width: 100% !important;
}
.shimmer-title--secondary {
  margin-bottom: 0px !important;
}
/*  */
.overlay {
  position: fixed;
  top: 3.5rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
/*  */
/* Colors */
.bg-blue {
  background-color: #00c5de !important;
}
.text-blue {
  color: #00c5de;
}
.text-gray {
  color: #a2a2a2 !important;
}
.bg-gray {
  color: #a2a2a2 !important;
}

.calendar-bg {
  background-color: #f6f6f6 !important;
}
.icon-style {
  width: 37px;
  height: 37px;
  background-color: #e4e6eb;
}
.bg_hover:hover{
  background-color: #5fdff7b0 !important;
  transition: 0.3s ease-in-out;
}
ul.control-dots {
  right: 0;
  width: auto !important;
  margin: 10px 16px 16px 16px !important;
  background: rgba(0, 0, 0, 0.3) !important;
  border-radius: 15px !important;
  display: flex !important;
  justify-content: center !important;
  padding: 10px !important;
}
.single-item ul.control-dots {
  display: none !important;
}
.br-22 {
  border-radius: 22px;
}
.br-17 {
  border-radius: 17px;
}
.object-cover {
  object-fit: cover;
}
.object-fill {
  object-fit: fill;
}

.box {
  box-shadow: 5px 3px 6px rgba(0, 0, 0, 0.16);
}
.form-box {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
}
.specialization-btn-shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.btr-40 {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.br-24 {
  border-radius: 24px;
}
.br-4 {
  border-radius: 4px;
}
.br-8 {
  border-radius: 8px;
}

.br-12 {
  border-radius: 12px;
}
.br-16 {
  border-radius: 16px;
}
.br-18 {
  border-radius: 24px;
}
.br-20 {
  border-radius: 20px;
}
.br-32 {
  border-radius: 32px;
}

.auth-bg {
  background-image: url("../images/auth/bg-vector.png");
  background-position: center;
  background-repeat: no-repeat;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.2) !important;
}

.cursor {
  cursor: pointer;
}

.object-contain {
  object-fit: contain;
}

.bg-recommended-loader {
  background-color: #f9f9f9;
}

.btn-blue {
  background-color: #00c5de;
  border-radius: 4px;
  color: white;
  font-size: 16px;
}
.btn-blue:hover {
  background-color: #04c1da;
}

a {
  text-decoration: none !important;
}

.bg-warn {
  background-color: rgba(163, 20, 20, 0.15);
}

/* Styling for the loader container */
.loader {
  display: inline-block;
  width: 33px;
  height: 33px;
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top: 4px solid #ffffff; /* Change the color to match your design */
  animation: spin 1s linear infinite; /* Apply the animation */
}

/* Keyframe animation for spinning */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg-fade {
  background-color: rgb(0, 0, 0, 0.4);
}
.top-div-img img {
  width: 25px;
  height: 25px;
}
.top-div-img span {
  font-size: 13px;
}

/* start create account manut */
.signup {
  background-color: #00000057;
  width: 100%;
  position: absolute;
  top: 0;
  height: 0px;
  overflow: hidden;
  transition: all 0.3s ease;
}

@media (min-width: 2205px) {
  .lr-center1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 1550px) {
  .lr-center2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.shadow-sid {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 15px;
}
.shadow-sid:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px;
}
.manue-hover {
  background-color: white;
  transition: all 0.3s ease;
}
.manue-hover:hover {
  background-color: #00000012;
  transition: all 0.3s ease;
}
.hover-blue {
  color: #000000cc;
  transition: all 0.3s ease;
}
.hover-blue:hover {
  color: #00c5de;
  transition: all 0.3s ease;
}
.see-all-course {
  color: #0d6efd;
}
.see-all-course:hover {
  color: #00c5de;
}
.transition {
  transition: all 0.3s ease-in-out;
}

/* end create account manut */
.size {
  width: 55px !important;
  height: 55px !important;
  object-fit: contain;
}

.specialization-btn-shadow.clicked {
  background-color: #00c5de !important;
  color: #ffffff;
}

.p-10 {
  padding: 10px;
}
.p-16 {
  padding: 16px;
}
.file-border {
  border: 2px dotted #00c5de;
}

button[disabled] {
  background-color: #00c5de;
  opacity: 0.5;
  cursor: not-allowed;
}

.mt-11 {
  margin-top: 11px;
}

.br-10 {
  border-radius: 10px;
}
.br-6 {
  border-radius: 6px;
}
.br-14 {
  border-radius: 14px;
}
.active {
  color: #00c5de;
}
.dropdown-shadow {
  box-shadow: 0 22px 20px rgba(22, 34, 36, 0.03);
}

.dropdown-avatar-shadow {
  box-shadow: 0 6px 7px rgba(0, 0, 0, 0.16);
}

.bg-grey {
  background-color: rgb(245, 247, 249);
}

.dropdown-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.16);
}

.btn-red {
  background: rgb(255, 23, 23) !important;
  opacity: 0.73;
  color: #ffffff;
}
.btn-red:hover {
  background: #ff1717;
  opacity: 1;
  color: #ffffff;
}
.btn-delete-red {
  background: #ff1717 !important;
  color: #ffffff;
  border: none;
}
.remove-btn {
  /* border: none; */
  /* background-color: #00C5DE; */
  color: #ff1717;
  border-radius: 10px;
  background: transparent;
}
.change-btn {
  /* border: none; */
  /* background-color: #00C5DE; */

  border-radius: 10px;
  background: transparent;
}

.text-red {
  color: #ff1717;
}

.bg-offwhite {
  background-color: #f1f1f1;
}

.w-39 {
  width: 39%;
}

.specloading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-30 {
  width: 30% !important;
}
.w-80 {
  width: 80% !important;
}
.br-96 {
  border-radius: 96px;
}
.profile-follow-btn {
  background: rgb(0, 197, 222, 0.3);
  border: 1px solid #00c5de;
  color: black;
  border-radius: 6px;
  box-shadow: 0 6px 10px rgba(206, 206, 206, 0.7);
}
.profile-message-btn {
  background: #e7e2fd;
  border: 1px solid #a68af5;
  color: black;
  border-radius: 6px;
  box-shadow: 0 6px 10px rgba(206, 206, 206, 0.7);
}

.activedescription {
  max-width: 500px;
  word-wrap: break-word;
}

.profilejobs {
  margin-top: 150px;
}

.groupsettingheight {
  margin-top: 170px;
}

.job-scroll {
  justify-content: center;
  overflow-y: auto;
  height: 78vh;
}
/* .creategroupbtn{
  position: fixed
} */

.selectedusersgroup {
  border-radius: 8px;
  background-color: transparent;
}

.creategroupheight {
  height: 50vh;
  overflow-y: scroll;
}

.createglobalgroupheight {
  height: 70vh;
  overflow-y: scroll;
}

.profilejobspinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.right-message {
  text-align: right;
  padding: 10px;
  color: black;
  border-radius: 20px 20px 0px 20px;
  border: #000 1px solid;
  /* padding-right: 30px; */
  margin-bottom: 2px;
  word-wrap: break-word;
  font-size: 20px;
  max-width: 300px;
  margin-left: 10rem;
}
.ticketchat {
  overflow-y: scroll;
}

.left-message {
  background-color: #ccc;
  color: #000;
  border-radius: 20px 20px 20px 0px;
  padding: 10px;
  margin: 5px;
  max-width: 300px;
  word-wrap: break-word;
}

.right-name {
  text-align: right;
  font-size: 10px;
}
.left-name {
  font-size: 10px;
}

.b-gray-1 {
  border: 1px solid #e8e8e8;
}

.ticketchatspinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.activeticketspinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ticketchat {
  height: 90vh;
  margin-bottom: 60px;
}

.load-more-btn {
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #ffffff;
  color: rgba(0, 0, 0, 0.4);
  font-size: 24px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.side-info-img {
  background-image: url("../images/feed/profile_bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 166px;
}

.p-20 {
  padding: 20%;
}

.pt-6 {
  padding-top: 16%;
}

/* login style start */
.login-bg {
  background: #f1f2f3;
}
.login-bg img[alt="loginTop"] {
  top: -40px;
  right: -50px;
  opacity: 0.3;
  width: 100px;
}
.login-bg img[alt="loginBottom"] {
  opacity: 0.2;
  bottom: -35px;
  left: -50px;
  width: 100px;
}
.login-bg img[alt="login-email"] {
  top: 12px;
  left: 14px;
  width: 10px;
  opacity: 0.7;
}
.input-icon {
  top: 15px;
  left: 11px;
  opacity: 0.7;
}
.login-bg img[alt="forgot-email"] {
  top: 11px;
  left: 14px;
  width: 16px;
  opacity: 0.7;
}
.login-bg img[alt="login-pass"] {
  top: 12px;
  left: 14px;
  width: 14px;
  opacity: 0.7;
}
.login-bg img[alt="login-show-eye"] {
  top: 13px;
  right: 13px;
  width: 15px;
  opacity: 0.7;
}
.text-normal-primay {
  color: #00c5de;
}
.btn-login {
  background-color: #00c5de !important;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0 10px 5px -10px #00c5de;
}
.btn-login:hover {
  box-shadow: 0 10px 20px -10px #fff;
}
.text-normal-black {
  color: #233446da;
}
.w-120px {
  min-width: 125px;
}
.password-eye {
  position: absolute;
  bottom: 19px;
  width: 23px;
  right: 26px;
}
.w-xl-75 {
  width: 75%;
}

.mb0 {
  margin-bottom: 0 !important;
}
/* login style end */

@media (max-width: 1440px) {
  .ellips {
    text-align: left;
    white-space: nowrap;
    width: 167px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.w_fit_content {
  width: fit-content !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

/* navbar responsive */
@media (max-width: 991px) {
  button.react-calendar__navigation__label {
    font-size: 17px !important;
    font-weight: 900;
  }
  .calender-w {
    width: 100% !important;
  }
  .naveShow {
    display: none;
    margin-top: 56px !important;
    background-color: white;
    z-index: 99;
    padding: 8px 0;
  }
  .d-none-991 {
    display: none;
  }
  .logo-991 {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .filter-991 {
    position: absolute;
    top: 7px;
    right: 113px !important;
  }
}
@media (max-width: 768px) {
  .block-account img {
    width: 50px !important;
    height: 50px !important;
  }

  .w-lg-23px {
    width: 17px !important;
  }
  .top-div-img img {
    width: 20px;
    height: 20px;
  }
  .top-div-img span {
    font-size: 13px;
  }
  .wh-md-50px {
    width: 50px !important;
    height: 50px !important;
  }
  .br-md-10px {
    border-radius: 10px !important;
  }
  .fs-md-28 {
    font-size: 28px;
  }
  .fs-md-16 {
    font-size: 16px;
  }
  .fs-md-15 {
    font-size: 15px;
  }
  .fs-md-14 {
    font-size: 14px !important;
  }
  .fs-md-12 {
    font-size: 12px;
  }
  .fs-md-10 {
    font-size: 10px;
  }
  .fw-md-700 {
    font-weight: 700;
  }
}
@media (max-width: 767px) {
  .edit-profile img {
    width: 90px !important;
    height: 90px !important;
  }
  .edit-profile input {
    font-size: 15px !important;
    font-weight: 700 !important;
  }
  .specialization li {
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .login-bg img[alt="loginTop"] {
    right: -7px;
  }

  .profile-991 {
    position: absolute;
  }
  .account-tooltip-768 {
    margin: 3.4rem 0px 0 17px !important;
  }

  .fs-sm-16 {
    font-size: 16px;
  }
}
.w-70 {
  width: 70px;
}
.h-70 {
  height: 70px;
}
.feed-form-shadow {
  box-shadow: 0 3px 6px rgba(22, 34, 36, 0.03);
}

textarea {
  resize: none !important;
}

.jobs-side-shadow {
  box-shadow: 0 18px 12px rgba(0, 0, 0, 0.04);
}
.jobs-preview-shadow {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.04);
}

.text-orange {
  color: #f58c4a;
}
.text-green {
  color: #00de76;
}

.br-tl-12 {
  border-top-left-radius: 6px;
}
.br-bl-12 {
  border-bottom-left-radius: 12px;
}
.br-tr-12 {
  border-top-right-radius: 12px;
}
.br-br-12 {
  border-bottom-right-radius: 12px;
}

.brt-7 {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.b-1-gray {
  border: 1px solid rgba(112, 112, 112, 0.6);
}
.bg-green {
  background-color: #34c759;
}

.br-tl-5 {
  border-top-left-radius: 5px;
}
.br-5 {
  border-radius: 5px;
}

.br-b-5 {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.br-tr-5 {
  border-top-right-radius: 5px;
}
.b-b-blue {
  border-bottom: 3px solid rgb(221, 146, 90);
}

.bg-blue-opacity1 {
  background-color: rgba(3, 197, 222, 0.1);
}

.bg-blue-opacity1:hover {
  background-color: rgba(112, 112, 112, 0.15);
}

.event-marker {
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  border: 1px solid #00c5de;
  height: auto;
  border-radius: 10px;
  margin: 0px 5px;
}

.mt-7-rem {
  margin-top: 7rem !important;
}
.mt-9-rem {
  margin-top: 9rem !important;
}
.pt-7-rem {
  padding-top: 7rem !important;
}
.pt-9-rem {
  padding-top: 9rem !important;
}
.pt-10-rem {
  padding-top: 10rem !important;
}
.pb-6-rem {
  padding-bottom: 6rem !important;
}
.pb-15-rem {
  padding-bottom: 15rem !important;
}
.overflowX-hidden {
  overflow-x: hidden !important;
}
.overflowY-hidden {
  overflow-y: hidden !important;
}
.overflowX-auto {
  overflow-x: auto !important;
}
.overflowY-auto {
  overflow-y: auto !important;
}

.local-video video {
  object-fit: none !important;
}

.scroll-hide::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-hide {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.profile-bg {
  background-color: white;
}
.profile-bg:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

textarea::placeholder {
  color: rgba(0, 0, 0, 0.2) !important;
}

/* setting */

.pxy-20 {
  padding: 26px 20px;
}
.pxy-17 {
  padding: 6px 17px !important;
}
.border1 {
  border: 1px solid #707070;
}
.box-shadow1 {
  box-shadow: #0000000d 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;
}
.box-shadow2 {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.specialization li {
  padding: 12px 20px;
  box-shadow: #0000003d 0px 3px 8px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.mt--12 {
  margin-top: -12px;
}

.text-dark-gray {
  color: #3c2f2f;
}

.btn-cancel {
  background: white !important;
  color: #000000 !important;
  box-shadow: #00000005 0px 1px 3px 0px, #1b1f2326 0px 0px 0px 1px;
  border: none !important;
}
.calender-w {
  width: 50%;
}
.specialization-w {
  width: 75%;
}
.wh-lg-60px {
  width: 60px;
  height: 60px;
}

.w-lg-23px {
  width: 23px;
}
.pr-62px {
  padding-right: 62px;
}
@media (max-width: 1199px) {
  .w-lg-100 {
    width: 100%;
  }
  .edit-profile form {
    width: 90% !important;
  }
  .specialization-w {
    width: 100%;
  }
}

.editprofileinfo {
  margin-top: 180px;
  margin-bottom: 20px;
}

.editscroll {
  overflow-y: scroll;
  height: 100vh;
}

.block-account img {
  width: 55px;
  height: 55px;
  border-radius: 10px;
  padding: 3px;
}
.block-account span {
  border-radius: 10px;
  background: white;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.block-account h6 {
  font-weight: 400;
}

.profile_img {
  border-radius: 10px;
  background: white;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.edit-profile img {
  width: 115px;
  height: 115px;
  border-radius: 10px;
  padding: 3px;
  object-fit: cover;
}
.edit-profile .crox {
  color: black;
  position: absolute;
  right: 7px;
  top: 10px;
  cursor: pointer;
}
.edit-profile .plus {
  color: black;
  position: absolute;
  bottom: 7px;
  right: 7px;
  cursor: pointer;
}

.edit-profile input {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: #0000003d 0px 0px 3px;
}

.edit-profile textarea {
  width: 100%;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: #0000003d 0px 0px 3px;
}
.opacity-75 {
  opacity: 75%;
}

.create-complaint select {
  box-shadow: #43475545 0px 0px 0.25em, #5a7dbc0d 0px 0.25em 1em;
}
.create-complaint textarea {
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: #0000000d 0px 0px 0px 1px, #d1d5db 0px 0px 0px 1px inset;
}
.bg-aqua {
  background-color: #518e95;
}
.bg-auth{
  background-color: #00c5de !important;
}
@media (min-width: 1600px) {
  .pt-xl-9rem {
    padding-top: 9rem !important;
  }
}

.followingmodal {
  height: 50vh;
}

.editprofilearrow {
  padding-left: 10px;
}

.editbtn {
  margin-left: 210px;
}

@media (min-width: 1900px) {
  .pt-xl-14rem {
    padding-top: 14rem !important;
  }
}

.slider:before {
  background-color: transparent !important;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.row.mt-3 .slider {
  background-color: #f1f1f1 !important;
  border-radius: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  border: 1px solid #00c5de;
}

.slider.round:before {
  border-radius: 50%;
}

.groupphoto {
  width: 450px;
  height: 450px;
}

.added {
  /* Styles for the 'Added' state */
  color: white;
  background-color: black;
  border-radius: 5px;
  padding: 6px;
  border: 1.5px solid;
  border: none;
}

.not-added {
  /* Styles for the 'Add' state */
  color: black;
  border-radius: 5px;
  background-color: transparent;
  padding: 6px 12px;
}
.modal-title {
  width: 100%;
}

.groupimageshadow {
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  left: 50%;
  transform: translateX(-50%);
  background-color: #2e2d2d;
  color: #fff;
  font-size: 14px;
  opacity: 0.9;
  display: block;
  z-index: 1;
}

.tab-bg {
  background-color: #ffffff;
}
.tab-bg:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: xx-large;
  font-weight: 700;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 0px 15px;
  box-shadow: 5px 3px 6px rgba(0, 0, 0, 0.16);
}

button.react-calendar__navigation__label {
  font-size: x-large;
  font-weight: 700;
  text-transform: uppercase;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: xx-large;
  font-weight: 700;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 0px 15px;
  box-shadow: 5px 3px 6px rgba(0, 0, 0, 0.16);
}

abbr[title] {
  text-decoration: none !important;
  font-size: medium;
}

.react-calendar__tile--now {
  background: #04c1da !important;
  color: white;
  font-weight: 600;
  border-radius: 10px;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  position: relative;
}

button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: #04c1da !important;
  color: white !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #f6f6f6 !important;
  color: #000;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

.suggest_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
  color: #000000;
}
.clinical-sec-card {
  min-width: 23rem;
}
.w-320px {
  width: 320px;
}
@media (max-width: 480px) {
  .pr-62px {
    padding-right: 0px;
  }
  .w-320px {
    width: 100%;
  }
  .search-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  .search-post-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }
  .clinical-sec-card {
    min-width: 100%;
  }
  .clinical-sec-card h6 {
    font-size: 14px;
  }
  .text-ellips-162px {
    text-wrap: nowrap;
    width: 99%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .text-ellips-149px {
    width: 149px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .text-ellips-110px {
    width: 110px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (max-width: 360px) {
  .right_15px {
    top: -2px;
    right: -6px;
  }
  .w_fit_content {
    width: fit-content !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}

.m--7px {
  margin-left: -7px !important;
}
.custom-modal-size {
  max-width: 500px; /* Adjust the width to make it smaller */
}

@media (max-width: 576px) {
  .custom-modal-size {
    max-width: 90%; /* Ensure responsiveness on smaller screens */
  }
}
   /* chatInput  reactlead */
.chat-input-container {
  background-color: #f8f9fa;
  max-width: 100%;
}

.chat-textarea {
  flex: 1;
  border-radius: 20px;
  resize: none;
  min-height: 40px;
  max-height: 150px;
  padding-right: 50px; /* Space for image icon */
  overflow-y: auto;
  background-color: #f8f9fa;
}

.icon-smile {
  color: #8e8e8e;
  cursor: pointer;
}

.icon-image-plus {
  color: #8e8e8e;
  cursor: pointer;
}

.icon-image-plus:hover,
.icon-smile:hover {
  color: #262626;
}

textarea:focus {
  box-shadow: none;
}
/* Add this CSS for the chat container reactlead*/
.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  /* Always align messages at the bottom */
  height: calc(100vh - 210px);  /* Ensure the chat has a fixed height */
  overflow-y: auto;  /* Allow scrolling when there are many messages */
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full height of the container */
}

input[type="file"] {
  display: none;
}
.selected-chat-bg {
  background-color: rgba(0, 197, 222, 0.1);
}
.custom-select {
  appearance: none; /* Removes the default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 40px; /* Space for the custom icon */
  
  background-size: 12px; /* Adjust the icon size */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Aligns the icon to the right side */
  
  border: 1px solid #ced4da;
  border-radius: 4px;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
/* date input */

.date-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.5rem;
  width: 100%;
  max-width: 300px;
  background-color: #fff;
}

.custom-date-input {
  border: none;
  outline: none;
  width: 100%;
  font-size: 1rem;
  color: #6c757d;
  padding-right: 2rem; /* Space for the icon */
  background-color: transparent;
  cursor: pointer;
}

.custom-date-input::placeholder {
  color: #6c757d;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #6c757d;
}
 
.caption-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clinical_card {
  flex: 1 1 calc(50% - 1.5rem); /* Take up 50% of the row minus the gap */
  min-width: 300px; /* Ensure a minimum width */
  max-width: calc(46% - 1.5rem); /* Set a maximum width */
  box-sizing: border-box; /* Include padding and border in the element's total width */
}


 /* shimmer effect css for clincal session types */
 .shimmer-wrapper {
  position: relative;
  overflow: hidden;
  background-color: #EBEDF0; /* Updated background color */
  border-radius: 8px;
  height: 100%;
}

.shimmer {
  width: 100%;
  height: 100%;
  animation: shimmer 1.5s infinite linear;
  background: linear-gradient(
      to right,
      #EBEDF0 0%,
      #dcdfe3 50%, /* Slightly darker shade for shimmer */
      #EBEDF0 100%
  );
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}

.centered-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; Make sure this takes the full height of its parent */
  text-align: center;
  padding: 50px; /* Optional: for some padding around the text */
}

.centered-message-text {
  color: #6c757d; /* Optional: Adjust to your preferred color */
  font-size: 16px; /* Optional: Adjust font size */
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col-md-3 {
  padding-right: 0;
  padding-left: 0;
}

.custom-timepicker.ant-picker,
.custom-timepicker.ant-picker-outlined {
  width: 100%;
  border-color: #ced4da !important; /* Default Bootstrap border color */
  transition: none !important; /* Disable transition effects */
}

.custom-timepicker.ant-picker:hover,
.custom-timepicker.ant-picker-outlined:hover,
.custom-timepicker.ant-picker:active,
.custom-timepicker.ant-picker-outlined:active,
.custom-timepicker.ant-picker:focus-within,
.custom-timepicker.ant-picker-outlined:focus-within {
  border-color: #ced4da !important; /* Prevent border color change on hover, active, and focus-within */
  box-shadow: none !important; /* Disable shadow effect */
}

.custom-timepicker.ant-picker-focused,
.custom-timepicker.ant-picker:focus-within,
.custom-timepicker.ant-picker-outlined:focus-within {
  border-color: #86b7fe !important; /* Bootstrap-like focus border color for outer div */
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important; /* Bootstrap-like focus ring */
}

.custom-timepicker .ant-picker-input > input {
  border-radius: 0.25rem; /* Match Bootstrap's border radius */
  font-size: 1rem; /* Match Bootstrap's font size */
}
.custom-shimmer{
  width:80px !important;
  height:5px !important
}
.hide-label .MuiFormLabel-root.Mui-focused {
  display: none;
}
.custom-input input {
  border-radius: 0.25rem;
  transition: box-shadow 0.2s ease-in-out;
}


/* Apply glow effect on focus */
.custom-input .MuiOutlinedInput-root.Mui-focused {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Custom glow effect */
  border-color: rgba(0, 123, 255, 0.5);
}

/* Prevent the label from shifting upwards */
.custom-input .MuiInputLabel-root {
  transform: translate(14px, 12px) scale(1);
  transition: none;
}
.css-1ll44ll-MuiOutlinedInput-notchedOutline{
  border-color: #DEE2E6;
}
.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 11.5px 14px !important;
}
.css-qwdxx6{
  padding: 11.5px 14px !important;
}
.custom-input .MuiInputLabel-root.Mui-focused {
  display: none; /* Hide the label on focus */
}
.css-jupps9-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: transparent !important;
}

/* Ensure full width for the custom input */
.custom-input {
  width: 100%;
}

.custom-input .MuiOutlinedInput-root {
  width: 100%; /* Ensure the input itself is full width */
}

.custom-input .MuiInputBase-root {
  width: 100%; /* Set full width on the input base */
}

/* Keep other custom styling intact */
.custom-input .MuiOutlinedInput-root.Mui-focused {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-color: rgba(0, 123, 255, 0.5);
}
/* Target the input container to set a fixed height */
.custom-input .MuiOutlinedInput-root {
  height: 40px !important; /* Adjust height as needed */
}

/* Adjust inner padding to influence height */
.custom-input .MuiInputBase-input {
  padding: 14px !important; /* Adjust padding as needed */
  height: auto !important;
}

/* Ensure the icon aligns with new height */
.custom-input .MuiOutlinedInput-adornedEnd {
  padding-right: 12px !important; /* Adjust if needed */
}
/* Remove hover border color */
/* Set default border color */
.custom-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #dee2e6 !important;
}

/* Maintain the same border color on hover */
.custom-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #dee2e6 !important;
}

/* Keep the same border color on focus */
.custom-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #dee2e6 !important;
}

/* Remove focus shadow if present */
.custom-input .MuiOutlinedInput-root.Mui-focused {
  box-shadow: none !important;
}

.comment-text {
  display: inline;
}

@media (max-width: 990px) {
  .comment-text {
    display: none;
  }
}
.suggested_shimmer .shimmer-title-line:last-child {
  width: 100% !important;
}
.suggested_shimmer .shimmer {
  border-radius: 6px ;
}
.suggested_shimmer .shimmer-thumbnail{
  margin-bottom: 0px !important;
}


.break-line-category {
  display: block; /* Makes the span behave like a block element */
  word-wrap: break-word; /* Breaks long words if necessary */
  white-space: normal; /* Allows the text to wrap */
  margin-bottom: 10px; /* Optional: Adds space between the span and the following content */
}


/* ========= Course Detail Page Design ========== */
.customize_carousel .carousel-root{
  width: 350px;
}
.custom-box {
  background-color: #f8ffff; /* Light blue */
  border: 1px solid #dee2e6; /* Light gray border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border-radius: 5px; /* Rounded corners */
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
.progress-poll {
  height: 6px;
  background-color: #ddf6fa; /* Light gray for the progress background */
  border-radius: 5px; /* Rounded corners */
}

.progress-bar-poll {
  background-color: #1dc1dd; /* Bright blue */
  height: 100%; /* Ensures it fills the container height */
  border-radius: 5px; /* Rounded corners for the bar */
}

.user-images img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid white;
  margin-left: -8px; /* Overlapping effect */
}

.verified-icon {
  font-size: 0.8rem;
  color: #1dc1dd;
  margin-left: 4px;
}

.list-item-poll {
  border: 1px solid #dee2e6; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.avatar-wrapper {
  display: flex;
  position: relative; /* Make the wrapper container relative so child elements can be positioned */
}

.avatar-item {
  /* position: absolute; Position absolutely inside the wrapper */
  width: 24px; /* Adjust the image size */
  height: 24px;
  margin-left: -10px;
}

.avatar-item img {
  border-radius: 50%; /* Circular border */
  border: 2px solid #1dc1dd; /* Optional white border */
}
.hover-effect {
  padding: 5px; /* Add padding for spacing around the content */
  border-radius: 8px; /* Optional: For rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.hover-effect:hover {
  background-color: #F8FFFF; /* Light gray background on hover */
}
.upload-poll-image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.upload-poll-image-preview {
  width: 388px; /* Adjust width */
  height: 215px; /* Adjust height */
  /* object-fit: cover; */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.upload-poll-image-actions {
  margin-top: 15px; /* Adds spacing between the image and icons */
  display: flex;
  justify-content: center;
  gap: 10px; /* Spacing between the icons */
}

.upload-poll-image-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* .upload-poll-image-delete-icon {
  filter: invert(30%) sepia(60%) saturate(750%) hue-rotate(0deg);
} */

/* .upload-poll-image-edit-icon {
  filter: invert(40%) sepia(50%) saturate(550%) hue-rotate(200deg);
} */


.custom-card {
  position: relative;
  width: 129px;
  height: 93px;
  border-radius: 8px; /* Rounded corners for the card */
  overflow: hidden; /* Ensures content stays within bounds */
}

.custom-image {
  display: block;
  width: 100%;
  border-radius: 8px; /* Matches the card's rounded corners */
}

.custom-close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: white; /* Close button background */
  border: none;
  border-radius: 50%; /* Circular shape */
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px; /* Size of the 'X' icon */
  cursor: pointer;
}

.custom-close-btn::after {
  content: "×"; /* 'X' symbol */
  font-size: 14px;
  color: black; /* Close icon color */
  line-height: 1;
}

.uploaded-image-thumbnail {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.image-options {
  /* position: absolute; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  /* background: rgba(0, 0, 0, 0.5); */
  padding: 5px;
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.image-options button {
  padding: 0;
  color: #848798 !important;
}

.upload-image-button {
  width: 150px;
  height: 150px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #666;
  transition: all 0.3s ease;
}

.upload-image-button:hover {
  border-color: #999;
  color: #333;
}

.upload-image-button i {
  font-size: 24px;
  margin-bottom: 5px;
}
.custom-nav {
  background-color: white; 
  color: black;
  border: none !important;
}

.custom-nav .nav-link {
  color: black; 
  /* / border-bottom: none !important; Prevent border bottom for all nav-links / */
}

.custom-nav .nav-link:hover {
  color: #1dc1dd;
  /* / border-bottom: none !important; Ensure no border-bottom on hover / */
}

.custom-nav .nav-link.active {
  color: white; 
  /* font-weight: bold; */
  border-left: 0.5px solid #1dc1dd !important;
  border-right: 0.5px solid #1dc1dd !important;
  border-top: 0.5px solid #1dc1dd !important;
  /* / border-bottom: none !important; Make sure there's no bottom border on the active tab / */
}

.nav-tabs .nav-link{
  border: none !important;
}
.custom-arrow {
  position: absolute;
  padding: 11px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.custom-arrow.prev-arrow {
  left: 0;
}

.custom-arrow.next-arrow {
  right: 0;
}

.carousel:hover .custom-arrow {
  visibility: visible;
  opacity: 1;
}


/* =========== Course Detail Chapter CSS ============ */

@-moz-document url-prefix() {
  .iframe-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: white;
    z-index: 1000;
  }
}
/* / Full-screen modal styles / */
.fullscreen-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  z-index: 99999;
}

.custom_pdf_viewer.full-screen iframe {
  height: 100vh; 
}

.modal-body-view {
  padding: 0;
  overflow: hidden;
}

/* / Optional: Add a background overlay to dim the screen when fullscreen / */
.fullscreen-modal .modal-content {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  height: 100%;
}

/* Hide the default radio button */

.custom-radio input {
  display: none;
}

.custom-radio .radio-checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

/* Base checked state for radio buttons only (when not correct/incorrect) */
.custom-radio:not(.correct):not(.incorrect) input:checked + .radio-checkmark {
  border-color: #1dc1dd;
}

/* Add the blue dot for checked radio buttons only when not correct/incorrect */
.custom-radio:not(.correct):not(.incorrect) input:checked + .radio-checkmark::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #1dc1dd;
  border-radius: 50%;
  display: block;
}

/* Correct answer styles */
.custom-radio.correct {
  color: #00c400;
}

.custom-radio.correct .radio-checkmark {
  border-color: #00c400;
}

/* Incorrect answer styles */
.custom-radio.incorrect {
  color: #ff3b30;
}

.custom-radio.incorrect .radio-checkmark {
  border-color: #ff3b30;
}

/* Show gray dot only for incorrect selected answers when disabled */
.custom-radio.incorrect input:disabled:checked + .radio-checkmark::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #c6c6c6;
  border-radius: 50%;
  display: block;
}

/* Disabled states */
.custom-radio input:disabled + .radio-checkmark {
  opacity: 0.7;
}



