.transaction-wrapper {
    max-width: 600px; /* Sets a consistent width */
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    margin: 0 auto; /* Centers the wrapper */
  }
  
  /* Header styling */
  .transaction-header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
  }
  
  .credit-text {
    font-weight: bold;
    color: #28a745; /* Green color for credit */
  }
  
  .transaction-date {
    color: #6c757d;
    font-size: 0.9rem;
  }
  
  /* Details styling */
  .transaction-item {
    padding: 8px 0;
    /* border-bottom: 1px solid #ddd; */
  }
  
  .transaction-item:last-child {
    border-bottom: none;
  }
  
  .details-section h6 {
    font-weight: bold;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
  }
  
  .user-text p {
    margin: 0;
    font-weight: bold;
  }
  
  .user-text span {
    font-size: 0.85rem;
    color: #6c757d;
  }
  
  /* .text-gray {
    color: #6c757d;
    text-align: center;
    padding-top: 20px;
  } */
 