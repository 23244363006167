@font-face {
  font-family: NotoSans-bold;
  src: url(../../assets/fonts/static/NotoSans-ExtraBold.ttf);
}
@font-face {
    font-family: NotoSans-semibold;
    src: url(../../assets/fonts/static/NotoSans-SemiBold.ttf);
  }
@font-face {
  font-family: NotoSans-medium;
  src: url(../../assets/fonts/static/NotoSans-Medium.ttf);
}
@font-face {
  font-family: NotoSans-regular;
  src: url(../../assets/fonts/static/NotoSans-Regular.ttf);
}

.manag_content h4 {
  font-family: NotoSans-bold;
}
.manag_content p{
    font-family: NotoSans-semibold;
    font-size: 17px;
}
.content_subtitle p{
    font-family: NotoSans-semibold;
    font-size: 20px;
}
.manag_content h5{
    font-size: 22px;
    color: #00c5de;
    font-family: 'NotoSans-bold';
}
.manag_content small{
    font-family: NotoSans-medium;
    font-size: 18px;
}
.btn_new {
  background-color: #000000;
  border: none;
  outline: none;
  padding: 8px 15px;
  font-family: NotoSans-medium;
  color: #ffffff;
}
.btn_new_main {
  background-color: #1dc1dd;
  border: none;
  outline: none;
  padding: 8px 15px;
  font-family: NotoSans-medium;
}
.cancel_btn{
    border: none;
    background-color: #000000;
    font-family: NotoSans-bold;
    font-size: 16px;
    text-align: white;
    padding: 14px 16px;
    width: 48%;
}
.delete_btn{
    border: none;
    background-color: #D90808;
    font-family: NotoSans-bold;
    font-size: 16px;
    text-align: white;
    padding: 14px 16px;
    width: 48%;
}
.add_chapter label{
    font-family: NotoSans-medium;
}
.add_chapter input{
    font-family: NotoSans-regular;
    border-radius: 7px;
    border: 1px solid #000000;
    outline: none;
    padding: 8px 15px;
    /* color: #808080; */
}