.upload-video {
  border: 2px dashed #ddd;
  padding: 50px;
  text-align: center;
  border-radius: 8px;
  height: 164px;
  cursor: pointer;
  position: relative; /* Important for absolute positioning of remove button */
}

.upload-video .icon {
  font-size: 2rem;
  color: #6c757d;
  margin-bottom: 10px;
}

.upload-video:hover {
  /* Optional hover effect */
  /* border-color: #007bff; */
  cursor: pointer;
}

.video-link-input {
  margin-top: 20px;
}

.file-preview {
  position: relative;
  width: 100%; /* Makes the preview fit inside the container */
  height: 15rem; /* Adjust the height to match your requirement */
  margin: 10px 0;
  text-align: center;
  border-radius: 8px;
  border: 2px dashed #ddd;
  cursor: pointer;
  overflow: hidden; /* Ensures that the content does not overflow */
}

.file-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  border-radius: 8px;
}

.remove_btn {
  position: absolute;
  top: 5px; /* Slightly inside the container */
  right: 5px; /* Adjust position so it stays inside */
  background: white;
  border: none;
  font-size: 24px; /* Adjust the font size for visibility */
  color: black;
  cursor: pointer;
  z-index: 10; /* Ensure the cross icon stays on top */
}

.pdf-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.pdf-preview img {
  width: 30px; /* Small PDF icon */
}

.modal-body-view {
  padding: 0; /* Remove padding to fit content neatly */
}
/* For file preview */
.pdf-view-preview {
  position: relative;
  width: 100%; /* Makes the preview fit inside the container */
  height: 15rem; /* Adjust the height as needed */
  margin: 10px 0;
  text-align: center;
  border-radius: 8px;
  border: 2px dashed #ddd;
  cursor: pointer;
  overflow: hidden; /* Ensures that the content does not overflow */
}

/* PDF preview container */
.pdf-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%; /* Ensures the container fills the height of file-preview */
}

/* For PDF image inside the preview */
.pdf-preview img {
  width: 60px; /* Larger size */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Keeps the aspect ratio intact */
}

.video-file-preview {
  position: relative;
  width: 100%; /* Makes the preview fit inside the container */
  height: 100%; /* Ensures the container fills the available height */
  margin: 10px 0;
  text-align: center;
  height: 160px;
  border-radius: 8px;
  border: 2px dashed #ddd;
  cursor: pointer;
  overflow: hidden; /* Ensures that the content does not overflow */
}

.video-file-preview video {
  width: 100%; /* Makes the video stretch to fit the width */
  height: 100%; /* Makes the video stretch to fit the height */
  object-fit: cover; /* Ensures the video scales correctly while maintaining its aspect ratio */
  border-radius: 8px; /* Optional, if you want rounded corners for the video */
}

.video-remove-btn {
  position: absolute;
  top: 5px; /* Adjust as needed */
  right: 5px; /* Adjust as needed */
  background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent background */
  border: none;
  font-size: 20px;
  color: red;
  cursor: pointer;
  border-radius: 50%;
  width: 30px; /* Button size */
  height: 30px; /* Button size */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure the button is on top */
}

.video-remove-btn:hover {
  background-color: rgba(255, 255, 255, 0.8); /* Hover effect */
}

/* ==========  Course Page Shimmer ============= */
.custom_course_shimmer .shimmer-card{
  position: absolute;
  bottom: 10px;
  background: linear-gradient(0deg, rgba(96,93,93,1) 0%, rgba(217,217,217,0.8211659663865546) 100%) !important;
  /* box-shadow: none !important; */
  padding: 20px 20px 0 14px !important;
}
.custom_course_shimmer .shimmer-card .shimmer-avatar{
  width: 20px !important;
  height: 20px !important;
}
.custom_course_shimmer .shimmer-card .flex-1{
  padding-right: 24px !important;
  padding-left: 7px !important;
  padding-top: 8px !important;
}
.custom_course_shimmer .shimmer-card .shimmer-text .shimmer-text-line:nth-child(1){
  width: 40% !important;
}
.custom_course_shimmer .shimmer-card .shimmer-text .shimmer-text-line:nth-child(3){
  width: 100% !important;
}
.custom_course_shimmer .shimmer-card .shimmer-text .shimmer-text-line:nth-child(2){
  display: none !important;
}
.custom_course_shimmer .shimmer-card .shimmer-button{
  width: 45px !important;
  height: 13px !important;
}

/* ==========  Course Detail Page Shimmer =========== */

/* .course_edit_shimmer .shimmer-title-line,
.course_edit_shimmer .detail_buttons_shimmer .shimmer-button,
.course_edit_shimmer .d-flex .detail_button_shimmer .shimmer-button,
.course_edit_shimmer div .detail_button_shimmer .shimmer-thumbnail,
.course_edit_shimmer .detail_category_shimmer .shimmer-avatar,
.course_edit_shimmer .shimmer-text .shimmer-text-line{
    background: linear-gradient(to right, #ebedf0 0%, #dcdfe3 50%, #ebedf0 100%);   
  } */
  /* background: linear-gradient(to right, #f0f7fa 0%, #e6eff2 50%, #f0f7fa 100%); */

.course_edit_shimmer .shimmer-title-line:first-child {
  width: 75% !important;
  height: 17px !important;
}
.course_edit_shimmer .shimmer-title-line:last-child {
  width: 40%;
  height: 17px !important;
}
.course_edit_shimmer .detail_buttons_shimmer .shimmer-button:first-child {
  width: 230px !important;
  height: 48px !important;
}
.course_edit_shimmer .detail_buttons_shimmer .shimmer-button:nth-child(2) {
  width: 130px !important;
  height: 48px !important;
}
.course_edit_shimmer .detail_buttons_shimmer .shimmer-button:last-child {
  width: 130px !important;
  height: 48px !important;
}
.course_edit_shimmer
  .d-flex
  .detail_button_shimmer
  .shimmer-button:first-child {
  width: 35px !important;
  height: 30px !important;
  margin-bottom: 0;
}
.course_edit_shimmer .d-flex .detail_button_shimmer .shimmer-button:last-child {
  width: 60px !important;
  height: 10px !important;
  margin-bottom: 0;
}
.course_edit_shimmer div .detail_button_shimmer .shimmer-thumbnail{
    width: 40%;
    min-width: 40%;
}
.course_edit_shimmer .detail_category_shimmer .shimmer-title-line:first-child{ 
    width: 45% !important;
    height: 15px !important;
}
.course_edit_shimmer .detail_category_shimmer .shimmer-title-line:last-child{ 
    width: 26% !important;
    height: 15px !important;
}
.course_edit_shimmer .detail_category_shimmer .flex{
    align-items: center;
}
.course_edit_shimmer .shimmer-text .shimmer-text-line .shimmer-text-line:first-child{
    width: 60% !important;
}
.course_edit_shimmer .custom_shimmer_line .shimmer-text .shimmer-text-line .shimmer-text-line:first-child{
    width: 100% !important;
}



/* ============ Trending Crausol Designing ============== */
.custom_carousel .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before{
  content: none !important;
}
.custom_carousel .carousel.carousel-slider{
  overflow: visible !important;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
  opacity: 1 !important;
}
/* .custom_carousel .carousel img{
  width: 16px !important;
} */

.course_shimmer .shimmer-card .shimmer-thumbnail{
  border-radius: 8px;
}