@font-face {
  font-family: NotoSans-bold;
  src: url(../../../assets/fonts/static/NotoSans-ExtraBold.ttf);
}
@font-face {
  font-family: NotoSans-medium;
  src: url(../../../assets/fonts/static/NotoSans-Medium.ttf);
}
@font-face {
  font-family: NotoSans-regular;
  src: url(../../../assets/fonts/static/NotoSans-Regular.ttf);
}

/* ============= NewLogin ================= */
.p-12 {
  padding-top: 13.6px;
  padding-bottom: 12px;
}
.pt-30 {
  padding-top: 30px;
}
.w_12 {
  width: 205px;
}

.account {
  width: 500px;
}
.setup-size {
  width: 480px;
  margin: auto;
}

.new_login_bg {
  background-color: #00c5de;
}
.log-box {
  background-color: #ffffff;
  border-radius: 16px;
}
.log-box h5 {
  font-size: 20px;
  font-family: "NotoSans-bold";
  margin-top: 8px;
  color: #031316;
}
.log-box p {
  font-family: "NotoSans-regular";
  color: #5c5c5c;
  font-size: 16px;
}
.res-pass .new_link {
  text-decoration: underline !important;
  color: #031316;
  font-family: "NotoSans-bold";
}
.log-box label {
  font-family: "NotoSans-medium";
  font-size: 16px;
}
.log-box label span {
  font-family: "NotoSans-regular";
  color: #5c5c5c;
}
.log-box .input-div {
  outline: none;
  border-radius: 10px;
  /* padding: 10px 35px;
     */
}
.log-box input,
.log-box textarea {
  outline: none;
  border: none;
  font-family: "NotoSans-medium";
  border: 1px solid #dae1e7;
  border-radius: 10px;
  outline: none;
  font-size: 14px;
  padding: 15px 8px 15px 35px;
  width: 100%;
  resize: none;
}
.new_main_btn {
  outline: none;
  border: none;
  background-color: #031316;
  border-radius: 10px;
  font-family: "NotoSans-medium";
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 17px;
  color: #ffffff;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.new_main_btn:hover {
  background-color: #052024;
  cursor: pointer;
}
.new_main_btn:active {
  background-color: #021012;
  transform: scale(0.98);
}


/*  ============== Login Footer ============= */
.footer_btn .dropdown .btn {
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  padding-bottom: 3px;
  font-size: 18px;
}
.footer_btnlogin-bg .btn:hover {
  background: transparent;
}
.footer_btn .dropdown-item.active,
.dropdown-item:active {
  background-color: #031316;
}
.footer-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
}

.footer-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1.5px;
  bottom: -2px;
  left: 0;
  background-color: white;
  transition: width 0.3s ease-in-out;
}

.footer-link:hover::after {
  width: 100%;
}
.footer-text {
  bottom: 17px;
}

/* =============== NewForgot Password ================== */
.newForgot img[alt="EMAIL_ICON"] {
  top: 19px;
  left: 12px;
  width: 16px;
  opacity: 0.7;
}

.otp-inputs input {
  border: 1px solid #dae1e7;
  border-radius: 13px;
  padding: 14px 10px 12px 10px;
  font-size: 17px;
  font-family: "NotoSans-bold";
}
.otp-inputs input:active,
.otp-inputs input:focus {
  border: 1px solid #00c5de !important;
  outline: 1px solid #00c5de !important;
}
.new_btn-2 {
  border: none;
  outline: none;
  border-radius: 10px;
  background-color: #5c5c5c;
  font-family: "NotoSans-medium";
  font-size: 17px;
}
.new-btn-origin {
  border: none;
  outline: none;
  background-color: #00c5de;
  font-family: "NotoSans-medium";
  font-size: 17px;
  border-radius: 10px;
}
.new-btn-origin:hover {
  background-color: #00c5de;
  cursor: pointer;
}

.new-btn-origin:active {
  background-color: #00c5de;
  transform: scale(0.98);
}
 .new_login_bg .success_msg {
  position: absolute;
  background-color: #125a5b;
  border: 1px solid #1b9842;
  border-radius: 10px;
  font-family: "NotoSans-medium";
  color: #ffffff;
  top: 30px;
  right: 25px;
  padding: 10px 15px 10px 15px;
  width: 265px;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.new_login_bg  .show {
  opacity: 1;
  transform: translateY(0);
}

.select-type {
  border: 1px solid #dae1e7;
  border-radius: 10px;
  transition: all 0.2s;
  &:hover {
    background-color: #f5f5f5;
  }
}
.select-type.active {
  background-color: #f5f5f5;
}
.select-type h5 {
  font-size: 17px;
  font-family: "NotoSans-bold";
  color: #323f49;
}
.select-type p {
  font-size: 14px;
  font-family: "NotoSans-regular";
  color: #323f49;
}
.set_position{
  top: 40px;
  left: 40px;
}

/* =============== SignUp Pages ==================== */
.select-type span {
  width: 20px;
  height: 20px;
  border: 1px solid #000000;
  border-radius: 50%;
}

.dropdown .dropdown-menu {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ced4da;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
}
.dropdown .btn-dropdown {
  background-color: #ffffff;
  color: #5c5c5c;
  border: 1px solid #ced4da;
  border-radius: 10px;
  font-family: "NotoSans-regular";
  font-size: 14px;
  padding: 15px 8px 15px 35px;
}
.dropdown-item {
  font-family: "NotoSans-regular";
}

/* =========== Login Responsiveness ============= */
@media screen and (max-width: 2560px) {
  /* .vh-100-lg {
    height: 100vh;
  } */
  .custom-absolute {
    position: absolute;
    bottom: 30px;
  }
  .newForgot {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .custom-height {
    height: 92vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vh_10 {
    height: 100vh;
    overflow: hidden;
  }
}
@media screen and (min-width: 1526px) {
  .signup_height{
    height: 100vh;
  } 
}
@media screen and (min-width: 1367px) {
  .vh-100-lg {
    height: 100vh;
  }
  .signup_height_log{
    height: 100vh;
  } 
  .hos_height{
    height: 100vh;
  }
}
/* @media screen and (max-width: 1800px) {
  .vh-100-lg {
    height: auto;
  }
} */
@media screen and (max-width: 1368px) {
  .vh_10 {
    height: auto;
  }
  .custom-absolute {
    position: inherit;
  }
  .custom-height {
    height: auto;
  }
  /* .custom-absolute{
    position: absolute;
    bottom: 30px
  } */
}
@media screen and (max-width: 1367px) {
  .footer-text {
    bottom: 0px;
    padding-bottom: 13px;
  }
  /* .select-type .account{
    width: 500px;
  } */
}
@media (max-width: 1120px) {
  .footer-text {
    width: 100%;
    overflow: scroll;
    overflow-y: auto;
  }
  .footer-text .text-white {
    text-wrap: nowrap;
  }
}
@media screen and (max-width: 768px) {
  .pt-30 {
    padding-top: 100px;
  }
}
@media screen and (max-width: 425px) {
  .responsive_signup_height{
    height: 100vh;
  }
  .w_12 {
    width: 170px;
  }
  .setup-size {
    width: auto;
  }
  .account {
    width: auto;
  }
  .custom-height {
    display: block;
  }
  .set_position{
    top: 20px;
    left: 20px;
  }
}
@media screen and (max-width: 360px) {
  .account {
    width: 280px;
  }
}
