.content-wrapper {
    max-width: 626px; /* Set a max-width to align both heading and card */
    width: 100%;
  }
  
  /* Heading styling */
  .section-heading {
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* Card container styling */
  .card-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* padding: 20px; */
    padding-top: 20px;
  }
  