.heading-title p:first-child {
  font-weight: 650;
}

.heading-title p:nth-child(2) {
  margin-top: 15px;
}
.upload-file {
  background: #f2fcfe 0% 0% no-repeat padding-box;
  border: 1px dashed #a2a2a2;
  border-radius: 6px;
  min-width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}
.upload-file p:nth-child(2) {
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
}

.upload-file p:nth-child(3) {
  margin-top: -10px;
  font-size: 14px;
}

.upload-btn {
  background: #00c5de 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #0000001f;
  border-radius: 4px;
  color: white;
  border: none;
  width: 160px;
  height: 45px;
  margin-top: 20px;
}

.modal-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}
.modal-heading p:first-child {
  color: #162224;
  font-weight: 500;
  font-size: 20px;
}

.modal-heading p:nth-child(2) {
  margin-top: -10px;
  font-weight: 500;
}
.file-btn {
  border: none;
  background: none;
  color: #ff1717;
  font-size: 18px;
  font-weight: 500;
  margin-top: -5px;
  padding-bottom: 5px;
}

.cancel-btn {
  margin-top: 20px;
  font-weight: 500;
}

.uploaded-image {
  min-width: 160px;
  min-height: 170px;
  /* margin-top: 20px; */
  padding: 0px 20px;
}
.uploaded-image img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}
.close {
  width: 30px;
  height: 30px;
  z-index: 1;
  margin-top: 5px;
  margin-left: -55px;
}
.close-video {
  width: 30px;
  height: 30px;
  z-index: 1;
  margin-top: 25px;
  margin-left: -37px;
}
.upload-status {
  margin-top: 50px;
  width: 40%;
}
.progress {
  margin-top: -15px;
}
.progressBar {
  margin-top: -10px;
  height: 15px;
}
.uploading {
  margin-top: 15px;
}
.success-mess {
  color: #258664;
}
.height {
  overflow: hidden;
}

.VideoInput_input {
  display: none;
}
.dividerr {
  border: 1px solid #707070;
  opacity: 0.16;
  width: 100%;
  margin-top: 5px;
}
.post-video {
  width: 220px;
  height: 200px;
  background: #f2fcfe 0% 0% no-repeat padding-box;
  border: 1px dashed #1622247f;
  border-radius: 6px;
  margin-left: 20px;
}
.position-absolute {
  position: absolute;
}

.postImg-error {
  color: red;
  margin-left: 20px;
}

.title-size {
  font-weight: 500;
  font-size: 14px;
}
.d-flex {
  display: flex;
}
.s-between {
  justify-content: space-between;
}
.f-wrap {
  flex-wrap: wrap;
}
.c-pointer {
  cursor: pointer;
}
.f-direction {
  flex-direction: column;
}

.heading {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-top: -25px;
}

.content-overflow {
  overflow-x: scroll;
}
.content-overflow::-webkit-scrollbar {
  width: 0px !important;
}

.content-overflow::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.content-overflow::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 0px;
}
.content-overflow::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.m-top20 {
  margin-top: 15px !important;
}
.cropperImg {
  margin-top: -25px;
}
.textarea {
  margin-top: 20px;
  position: relative;
}
.textarea textarea {
  width: 100%;
  height: 130px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a2a2a2;
  border-radius: 4px;
  font-size: 14px;
  color: #162224;
  padding: 10px 15px;
  outline: none;
  margin-top: 10px;
}
.course-feilds {
  margin-top: 20px;
  position: relative;
}
.course-feilds input {
  width: 100%;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a2a2a2;
  border-radius: 4px;
  color: #162224;
  padding: 0px 15px;
  outline: none;
  margin-top: 10px;
}
.error {
  position: absolute;
  top: 0px;
  right: 0px;
  color: red;
}
.description-error {
  position: absolute;
  top: 0px;
  right: 0px;
  color: red;
}
.course-img {
  width: 160px;
  height: 170px;
  margin-top: 20px;
}
.course-img img {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}
.close-icon {
  position: absolute;
  top: 0px;
  right: -35px;
  width: 30px;
  height: 30px;
}
.p-relative {
  position: relative;
}

.checkbox {
  border: 1px solid #a2a2a2;
  width: 20px;
  height: 20px;
  margin-top: 10px;
}

.select {
  margin-top: 15px;
}
.divider {
  border: 1px solid #707070;
  opacity: 0.16;
  width: 100%;
  margin-top: 25px !important;
  margin-left: 0px;
}

.options {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.options input {
  width: 70%;
  outline: none;
  height: 45px;
  margin-top: 0px;
  border: 1px solid #a2a2a2;
  border-radius: 4px;
  padding: 0px 15px;
}

.answers p:first-child {
  font-weight: 500;
}
.m-left {
  margin-left: 15px;
}

.align-item {
  align-items: center;
}

.margin-top {
  margin-top: 0px;
}

.red {
  width: 100%;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a2a2a2;
  border-radius: 4px;
  outline: none;
  padding: 0px 12px;
  color: #162224a0;
}

.modal-top {
  margin-top: 70px;
}
.title-section p:first-child {
  font-size: 18px;
  font-weight: 700;
  margin-top: -5px;
}

.font {
  font-size: 14px;
  font-weight: 400;
}
.react-time-picker__button {
  display: none !important;
}
.react-time-picker__wrapper {
  border: none !important;
  color: #162224a0 !important;
  font-size: 14px;
}

/* .startTime-main {
  margin-left: 35px;
} */
.startTime {
  border: none;
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.certificate {
  margin-top: 40px;
}

/* course content css */

.verticalLine {
  border-right: 2px solid #16222433;
  height: 100vh;
  margin-top: -20px;
}
.chapter p:first-child {
  font-weight: 700;
}
.chapter p:nth-child(2) {
  margin-top: -12px;
  font-size: 14px;
}
.upload-content {
  width: 200px;
  height: 200px;
  background: #f5f7f9 0% 0% no-repeat padding-box;
  border: 1px solid #16222433;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.chapter-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.m-left50 {
  margin-left: 50px;
}
.m-left20 {
  margin-left: 20px;
}
.color {
  color: #50595a;
  font-weight: 600;
}

.file-upload {
  width: 220px;
  height: 210px;
  background: #f2fcfe 0% 0% no-repeat padding-box;
  border: 1px dashed #1622247f;
  border-radius: 6px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-btn {
  background: #00c5de 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 3px #0000001f;
  border-radius: 4px !important;
  width: 220px;
  outline: none;
  border: none;
  color: white !important;
  height: 40px;
  margin-top: 20px;
  margin-left: 3px;
}

.VideoInput_video {
  width: 220px;
  height: 210px;
  background: #f2fcfe 0% 0% no-repeat padding-box;
  border: 1px dashed #1622247f;
  border-radius: 6px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upload_imgg img {
  width: 220px;
  height: 210px;
  object-fit: cover;
  bottom: 1px;
  border-radius: 6px;
  left: 1px;
  margin-top: 30px;
}
.uploadImg-close {
  bottom: 175px;
  left: 185px;
  position: absolute;
  width: 30px;
}
.uploadVideo-close {
  position: absolute;
  z-index: 1;
  left: 185px;
  top: 5px;
  width: 30px;
}
.css-heg063-MuiTabs-flexContainer {
  justify-content: space-between !important;
  margin-left: 23px !important;
}
.add-video {
  width: 198px;
  height: 198px;
  position: absolute;
  top: 21.5px;
  left: 2px;
  border-radius: 10px;
}
.paddingg {
  padding-right: 150px;
}
.css-1q2h7u5.Mui-selected {
  color: white !important;
  background: #00c5de 0% 0% no-repeat padding-box !important;
  border-radius: 4px !important;
  width: 160px !important;
}
.css-k008qs {
  justify-content: space-between !important;
  margin-left: 23px !important;
}
.videoUpload-status {
  margin-top: 50px;
  width: 350px;
}

.url_input {
  width: 50%;
}
.imgUrl-close {
  position: absolute;
  top: 30px;
  right: 5px;
}
.pdfIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white 0% 0% no-repeat padding-box;
  border: 1px solid #16222433;
  padding: 0px 20px;
  border-radius: 10px;
  width: 220px;
  height: 210px;
  margin-left: 30px;
  margin-top: 30px;
}
.closFile {
  position: absolute;
  top: 35px;
  right: 5px;
  width: 30px;
}

.youtubeIcon {
  background: white;
  width: 200px;
  height: 195px;
  border-radius: 10px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -35px;
}
