/* fonts.css */
@font-face {
  font-family: "Noto-regular";
  src: url("../fonts/static/NotoSans-Regular.ttf") format('truetype');
  
}

@font-face {
  font-family: "Noto-medium";
  src: url("../fonts/static/NotoSans-Medium.ttf") format('truetype');
}

@font-face {
  font-family: "Noto-bold";
  src: url("../fonts/static/NotoSans-Bold.ttf") format('truetype');
}

@font-face {
  font-family: "realRoboto-medium";
  src: url(../fonts/Roboto/Roboto-Medium.ttf);
}
@font-face {
  font-family: "realRoboto-regular";
  src: url(../fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
  font-family: "realRoboto-bold";
  src: url(../fonts/Roboto/Roboto-Bold.ttf);
}

/* Font famil classes */
.roboto-regular {
  font-family: "Noto-regular";
}

.roboto-medium {
  font-family: "Noto-medium";
}

.roboto-bold {
  font-family: "Noto-bold";
}
.realRoboto-medium{
  font-family: "realRoboto-medium";
}
.realRoboto-regular{
  font-family: "realRoboto-regular";
}
.realRoboto-bold{
  font-family: "realRoboto-bold";
}

/* Font Size classes */
.fs-40 {
  font-size: 40px;
}
.fs-26 {
  font-size: 26px;
}
.fs-24 {
  font-size: 24px;
}
.fs-23 {
  font-size: 24px;
}
.fs-22 {
  font-size: 22px;
}
.fs-21 {
  font-size: 21px;
}
.fs-20 {
  font-size: 20px;
}
.fs-18 {
  font-size: 18px;
}
.fs-16 {
  font-size: 16px;
}
.fs-15 {
  font-size: 15px;
}
.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}
.fs-13{
  font-size: 13px;
}
.fs-12 {
  font-size: 12px;
}
.fs-11 {
  font-size: 11px;
}
.fs-10 {
  font-size: 10px;
}
.fs-8 {
  font-size: 8px;
}
