.wallet-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px; /* Adjust the width as needed */
    width: 100%;
  }
  
  .withdrawal-text {
    color: #6c757d;
    font-size: 0.9rem;
    margin: 0;
  }
  
  .amount {
    color: #212529;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .button-row {
    border-top: 1px solid #cccccc; /* Adds a border to the top of the button row */
  }
  
  .button-col {
    border-right: 1px solid #cccccc; /* Adds the vertical divider between buttons */
  }
  
  .wallet-button {
    font-weight: bold;
    color: #212529;
    text-decoration: none;
  }
  .debit-credit-heading {
    font-weight: bold;
    /* margin-left: 60px; */
  }
  .custom-link-color {
    color: black !important;
    border: none !important;
    background-color: transparent;
  }
  
  .custom-link-color:hover {
    color: #1DC1DD !important;
  }
  .availableShimmer {
    max-width: 610px;
    margin: 10px auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    text-align: center;
    background-color: #f9f9f9;
  }
  .j-c-c {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .available-btn {
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
  }
  .available-btn button {
    font-weight: 700;
    font-size: 18px;
    border: none;
  }
  .available-btn button:hover {
    color: #00c5de;
  }
  .available-btn button:focus {
    border: none !important;
  }
  .available-btn button:first-child {
    border-right: 1px solid #ccc !important;
  }
  .available-btn .shimmer-button {
    margin: 0 !important;
  }
  .shimmer-badge_div {
    margin-bottom: 0 !important;
  }
  /* ====================== */
  .withdrawShimmer {
    max-width: 610px;
    margin: 10px auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 15px;
  }
  .withdrawShimmer h3 {
    font-size: 16px;
    font-weight: 700;
    color: #ff9500;
  }
  .withdrawShimmer h4 {
    font-size: 14px;
    font-weight: 400;
    color: #6c757d;
  }
  .border_top_bottom {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid#CCC;
    padding: 20px 0;
    margin: 20px 0;
  }
  .withdrawShimmer h2 {
    font-size: 16px;
    font-weight: 700;
    color: #212529;
  }
  .withdrawShimmer h6 {
    font-size: 16px;
    font-weight: 400;
    color: #212529;
  }
  .withdrawShimmer p {
    font-size: 14px;
    font-weight: 400;
    color: #a2a2a2;
    margin: 0;
  }
  
  .withdrawShimmer h1 {
    font-size: 24px;
    font-weight: 700;
    color: #212529;
  }
  .shimmer-avatar {
    margin: 0 !important;
  }
  
  /* =========================================== */
  .debitShimmer {
    max-width: 610px;
    margin: 10px auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 25px 20px;
  }
  .debitShimmer h3 {
    font-size: 16px;
    font-weight: 700;
    color: #28a745;
  }
  .debitShimmer h4 {
    font-size: 14px;
    font-weight: 400;
    color: #6c757d;
  }
  .debitShimmer h2 {
    font-size: 16px;
    font-weight: 700;
    color: #212529;
    margin: 0;
  }
  .border-bottom_shimmer {
    border-bottom: 1px solid#CCC;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  