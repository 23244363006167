.search-input {
  border: 1px solid rgba(29, 193, 221, 0.2);
  outline: none;
  border-radius: 8px;
  white-space: nowrap;
  background-color: rgba(0, 197, 222, 0.1);
  height: 40px;
  padding-left: 2.6rem;
  font-size: 15px;
  transition: width 0.3s ease;
}

.search-input.active {
  width: 100%;
}

.search-input:not(.active) {
  width: 75%;
}

.search-dropdown {
  position: absolute;
  z-index: 2;
  background: white;
  border: 1px solid rgba(29, 193, 221, 0.2);
  border-radius: 8px;
  width: 100%;
  transition: height 0.3s ease;
}

.text-muted {
  color: rgba(132, 135, 152, 1) !important;
}

.text-primary {
  color: rgba(29, 193, 221, 1) !important;
}
