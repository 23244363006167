.shimmer-clinical-type-card {
    overflow: hidden;
    position: relative;
}

.shimmer-clinical-type-icon,
.shimmer-clinical-type-text {
    position: relative;
    overflow: hidden;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.shimmer-clinical-type-text {
    margin-bottom: 8px; /* Ensure a bit of space between text elements */
}

.shimmer-clinical-type-icon::before,
.shimmer-clinical-type-text::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    animation: shimmer-clinical-type-animation 1.5s infinite;
}

@keyframes shimmer-clinical-type-animation {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.shimmer-clinical-type-rounded {
    border-radius: 10px;
}

.shimmer-clinical-type-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.shimmer-clinical-type-padding {
    padding: 16px;
}
