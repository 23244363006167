.lh-20 {
  line-height: 20px;
}
.Profile {
  /* // overflow-y: scroll !important; */
  height: 100vh;

  .profile-col {
    margin-top: 160px;
  }
  .card-title {
    font-size: 30px;
  }
  .spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .editprofile {
    display: flex;
    justify-content: space-between;
    width: 95%;
  }

  .blurcoverimage {
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    background-color: rgba(0, 0, 0, 0.6);
  }

  .loadimg {
    margin-top: 30px;
  }

  .name {
    margin-top: 70px;
  }
  .edit:hover {
    color: #00c5de;
    border: 1px solid #00c5de !important;
  }
  .chat {
    background-color: white;
    border-radius: 45px;
    border: 1px solid #d1d0d0;
    width: 48px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
  .chat:hover {
    color: #00c5de;
    border: 1px solid #00c5de;
  }
  .profilepic {
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }

  .profilepicborder {
    background-color: white;
    border-radius: 10px;
    margin-top: -55px;
    height: 108px;
  }

  .editprofilebtn {
    font-size: 14px;
    border-radius: 25px;
    padding: 8px 25px;
    border: 1px solid #d1d0d0;
  }

  .card-img-top {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  .image-text {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(39, 38, 38, 0.3);
    padding: 10px 14px;
    color: white;
  }
  .image-text-block {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(39, 38, 38, 0.3);
    padding: 10px 14px;
    color: white;
  }
  .image-text-block-dropdown {
    position: absolute;
    margin-top: 40px;
    top: 5px;
    right: 0;
    color: white;
  }

  .image-texts {
    background-color: rgba(39, 38, 38, 0.5);
  }

  .username {
    font-size: 18px;
  }

  .biotext {
    font-size: 15px;
  }

  .category {
    font-size: 15px;
    color: #0381ff;
  }
  .linktext {
    font-size: 16px;
    overflow-wrap: anywhere;
  }
  .link-profile {
    color: black;
    transition: all 0.3s ease;
  }
  .link-profile:hover {
    color: #00c5de;
  }
  .image-div {
    padding: 3px;
    border: 1px solid #d1d0d0;
    padding-right: 8px;
    padding-left: 8px;
  }

  .following {
    border: 2px solid black !important;
  }

  .image-div-cal {
    border: 1px solid #d1d0d0;
    padding-right: 8px;
    padding-left: 8px;
    height: 40px;
  }

  .image-div-loc {
    border: 1px solid #d1d0d0;
    padding-right: 8px;
    padding-left: 8px;
    height: 39px;
  }

  .follow-button {
    background-color: black;
    color: white;
  }

  .following-button {
    color: black;
  }
  .following-button:hover {
    color: #00c5de;
    border: 1px solid #00c5de;
  }
  .postbtn {
    background-color: white;
    font-size: 24px;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #d1d0d0;
    padding-right: 220px;
    padding-left: 20px;
    transition: box-shadow 0.3s ease-in-out;
  }

  .eventbtn {
    background-color: white;
    font-size: 24px;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #d1d0d0;
    padding-right: 210px;
    padding-left: 20px;
    transition: box-shadow 0.3s ease-in-out;
  }
  .jobbtn {
    background-color: white;
    font-size: 24px;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #d1d0d0;

    padding-right: 230px;
    padding-left: 20px;
    transition: box-shadow 0.3s ease-in-out;
  }
  .jobbtn:hover {
    box-shadow: 0px 4px 8px rgba(0.2, 0.2, 0.2, 0.2);
  }
  .eventbtn:hover {
    box-shadow: 0px 4px 8px rgba(0.2, 0.2, 0.2, 0.2);
  }

  .postbtn:hover {
    box-shadow: 0px 4px 8px rgba(0.2, 0.2, 0.2, 0.2);
  }
  .desc-post {
    font-size: 18px;
    color: #7d7c7c;
  }
}

@media (max-width: 768px) {
  .Profile .profilepic {
    width: 86px;
    height: 86px;
  }
  .Profile .profilepicborder {
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -42px;
  }
}
