/* Common styling for all cards */
.card-container {
    max-width: 600px;
    min-height: 160px; /* Adjust based on your layout requirements */
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Section heading styling */
  .section-heading {
    font-weight: bold;
    /* margin-left: 20px; */
    margin-bottom: 15px;
  }
  
  /* Consistent font styling */
  .text-gray {
    color: #6c757d;
    font-size: 0.9rem;
  }
  
  .amount {
    font-weight: bold;
    color: #212529;
  }
  
  .icon-placeholder {
    font-size: 1.5rem;
    color: #ff9900;
  }
  
  /* Align content within each card */
  .header-section,
  .transaction-header {
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
  }
  
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
  }
  
  .user-info {
    display: flex;
    align-items: center;
  }
  