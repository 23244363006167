.shimmer-button {
    border-radius: 4px;
    height: 25px!important;
    width: 88px!important;
    /* margin-bottom: 20px; */
}
.sm_shimmer_btn-1 .shimmer-button--sm{
    border-radius: 3px;
    height: 21px!important;
    width: 31px!important;
}
.shimmer_lg_btn{
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    /* height: 100vh; */
}
.shimmer_lg_btn .shimmer-button--lg {
    height: 48px!important;
    width: 140px!important;
    border-radius: 5px!important;
}
