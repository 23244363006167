.video-container {
    position: relative;
    width: 100%;
  }
  
  .video-tag {
    width: 100%;
    display: block;
    background: black;
  }
  
  .video-controls {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px 15px;
    border-radius: 8px;
    gap: 10px;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
  
  .video-controls.hide {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  
  .video-controls.show {
    opacity: 1;
    visibility: visible;
  }
  
  .control-btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
  
  .progress-bar {
    flex-grow: 1;
    -webkit-appearance: none;
    appearance: none;
    height: 4px;
    background: gray;
    border-radius: 2px;
    outline: none;
  }
  
  .time-display {
    color: white;
    font-size: 14px;
  }
  .mark-as-read-btn {
    position: absolute;
    top: 6px;
    right: 10px;
    background: #1dc0ddc2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .mark-as-read-btn:hover {
    background: #1dc1dd;
  }
  