/* Center align text in the input placeholder */
.withdraw-input {
    font-size: 1.2rem;
    text-align: left;
    padding-left: 20px;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    /* border: 1px solid #ddd; */
    border-radius: 4px;
    padding: 8px;
  }
  