$maximum: 1200px;
$desktop: 920px;
$tablet: 768px;
$mobile: 540px;

$container-padding: 24px;

$dark-theme-color: #6FCFF0;
$theme-color: #61DAFB;
$black-color: #212121;
$black-button-color: #36393F;
$black-button-color-hover:  #20232A;
$real-theme-color: #1aa7f9;
