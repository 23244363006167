.paypal-email {
    font-weight: bold;
    color: #333;
    margin-top: -5px;
  }
  
  .remove-button {
    background-color: white;
    color: black;
    border: 2px solid #333;
    font-weight: bold;
    padding: 6px 12px;
  }
  
  .remove-button:hover {
    background-color: #333;
    color: white;
  }
  
  .modal-footer .btn-primary {
    background-color: #00aaff;
    border: none;
    font-weight: bold;
    padding: 6px 12px;
  }
  